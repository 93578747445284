.article-component {
    position: relative;
}
.article-component .link-back {
    text-decoration: none;
    color: #323232;
    display: block;
    height: 25px;
}
.article-component .link-back img {
    display: block;
    margin-right: 20px;
}
.article-component .article-content .sentence-item {
    font-family: Inter;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 31px;
}
.article-component .action-bar {
    border-bottom: 2px solid #000000;
}
.article-component .action-bar .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}
.article-component .action-bar .btn-container {
    gap: 15px;
    position: relative;
    width: 300px;
}
.article-component .action-bar .btn-container .btn {
    cursor: pointer;
}
.article-component .action-bar .btn-container .hover-text {
    opacity: 0;
    position: absolute;
    right: 100px;
    transition: opacity 0.3s;
}
.article-component .action-bar .btn-container .btn.next:hover .hover-text {
    opacity: 1;
}
.article-component .action-bar .btn-container .btn.prev:hover .hover-text {
    opacity: 1;
}
.article-component .edit-bar {
    display: flex;
    justify-content: flex-end;
    padding-top: 17px;
    padding-bottom: 32px;
}
.article-component .edit-bar > div {
    display: flex;
    align-items: center;
    gap: 12px;
}
.article-component .article-logo img {
    display: inline-block;
    max-height: 80px;
    width: auto;
}
.article-component .sentence-item {
    padding: 3px;
}
.article-component .sentence-item.mostly-entail {
    border-bottom: 2px solid #7CDE82;
}
.article-component .sentence-item.mostly-entail.selected {
    background-color: #7CDE82;
    color: #ffffff;
    border-color: transparent!important;
}
.article-component .sentence-item.unimportant {
    color: #BBBBBB;
}
.article-component .sentence-item.unimportant.selected {
    color: #ffffff;
    background-color: #BBBBBB;
    border-color: transparent!important;
}
.article-component .sentence-item.mostly-refute {
    border-bottom: 2px solid #FE735A;
}
.article-component .sentence-item.mostly-refute.selected {
    background-color: #FE735A;
    color: #ffffff;
    border-color: transparent!important;
}

.article-component .sentence-item:hover {
    cursor: pointer;
}
.article-data {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
}
.article-data .content {
    display: flex;
    flex-basis: 100%;
}
.article-data .left-column {
    flex-basis: 40%;
}
.article-data .factcheck-container,
.article-data .evidence-container {
    flex-basis: 60%;
}

.article-data .left-column {
    display: flex;
    flex-direction: column;
}
.article-data .left-column .main-content {
    background-color: #FFFFFF;
    padding: 76px 70px;
    flex-basis: calc(100% - 133px);
    position: sticky;
    top: 40px;
}
.article-data .sticky-scroll-box {
    position: sticky;
    top: 40px;
}
.article-data .right-column {
    padding: 40px 69px;
    background-color: #2E3643;
}
.article-data .factcheck-container {
    background-color: #2E3643;
}
.article-data .factcheck-container .actions {
    display: flex;
    justify-content: space-between;
    background-color: #374151;
    padding: 25px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}
.article-data .factcheck-container .actions .sentence-toggle .btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.article-data .factcheck-container .actions .sentence-toggle .btn-container .btn img {
    display: block;
}
.article-data .factcheck-container .actions .btn.enabled {
    cursor: pointer;
}
.article-data .factcheck-container .actions .btn {
    display: inline-block;
}
.article-data .main-content .article-title {
    font-weight: bold;
    margin-bottom: 30px;
}
.article-data .source-info-toggle {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
.article-data .source-info {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    background-color: #F0F4F6;
    padding: 10px 12px;
}
.article-data .source-info .si-item {
    flex-basis: calc((100% - (12px * 3)) / 3);
    color: #000000;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
}
.article-data .source-info .si-item a {
    color: #000000;
}
.article-data .source-info .si-title {
    font-weight: 400;
    color: #888888
}
.article-data .source-info .si-item.description {
    flex-basis: 100%;
}

.error-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.75);
    padding: 31px;
}
.error-modal .em-inner {
    color: #ffffff;
    background-color: #1f2937;
    padding: 31px;
}
.error-modal .em-inner a {
    color: #ffffff;
    display: inline-block;
    margin-top: 15px;
    border: 1px solid #ffffff;
    padding: 8px 15px;
    text-decoration: none;
}

.chatbox {
    margin-top: 90px;
}
.chatbox .cb-message {
    border: 1px solid #D8D8D8;
    color: #161616;
    font-family: Inter;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 31px;
    padding: 25px 14.5px 25px 23.5px;
    width: 100%;
}
.chatbox .submit-message {
    color: #2D6EFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    padding: 15px 30px;
    border: 1px solid #2D6EFF;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 25px;
}

.article-component .article-data.layout-chatgpt .article-content .sentence-item {
    /*display: block;*/
    margin-bottom: 10px;
}


@media all and (max-width: 1000px) {
    .article-data .content {
        flex-wrap: wrap;
    }
    .article-data .content > * {
        flex-basis: 100%;
    }
}

@media all and (max-width: 600px) {
    .article-data .left-column .main-content {
        padding: 30px 40px;
    }
    .article-data .source-info {
        flex-wrap: wrap;
    }
    .article-data .right-column {
        padding: 30px 40px;
    }
}
