.generic-component-template {
    height: inherit;
    display: flex;
    flex-direction: column;
    background-color: #2E3643;
}

.generic-component-template .inner {
    flex-grow: 1;
}
