.evidence-component {
    background-color: #fafafa;
}
.evidence-component .actions {
    display: flex;
    justify-content: space-between;
    background-color: #374151;
    padding: 25px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}
.evidence-component .actions .btn-container .btn .enabled {
    cursor: pointer;
}
.evidence-component .actions .btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.evidence-component .evidence-data {
    background-color: #374151;
}
.evidence-component .action-bar-white {
    padding: 20px;
    background-color: #1F2937;

    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;

    border-bottom: 25px solid #2E3643;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.evidence-component .action-bar-white .btn-close {
    cursor: pointer;
    display: flex;
    gap: 12.8px;
    align-items: center;
}
.evidence-component .action-bar-white .claim-navigation {
    display: flex;
    gap: 12.5px;
    align-items: center;
}
.evidence-component .action-bar-white .claim-navigation .btn {
    cursor: pointer;
}
.evidence-component .action-bar-white .claim-navigation span {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.evidence-component .claim-summary {
    position: relative;
    padding: 25px 25px 25px 53px;
    margin: 0;
    background-color: #1F2937;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.3);
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.evidence-component .claim-summary .column-left {
    flex-basis: calc(100% - 160px);
}
.evidence-component .claim-summary .column-right {
    flex-basis: 160px;
    display: flex;
    flex-wrap: wrap;
}
.evidence-component .claim-summary .column-right > * {
    flex-basis: 100%;
}
.evidence-component .claim-summary .claim-line-icon {
    position: absolute;
    left: 17px;
    top: 25px;
}
.evidence-component .claim-summary .claim-number {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 11px;
}
.evidence-component .claim-summary .claim {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
}
.evidence-component .claim-summary .claim span {
    padding: 3px;
}
.evidence-component .claim-summary .claim span.mostly-entail {
    border-bottom: 3px solid #58bc61;
}
.evidence-component .claim-summary .claim span.mostly-refute {
    border-bottom: 3px solid #FE735A;
}
.evidence-component .claim-summary .claim-info {
    padding-top: 20px;
    display: flex;
    gap: 61px;
}
.evidence-component .claim-summary .claim-info .info-item {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
}
.evidence-component .claim-summary .claim-info .info-item .value {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: initial;
}
.evidence-component .claim-counter {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    padding: 19px 25px;
}
.evidence-component .tabs {
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
}
.evidence-component .tabs .tab-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-basis: 50%;
    background-color: rgba(255,255,255,0.1);
    padding: 20px 40px;
    font-weight: bold;
    cursor: pointer;
    color: #7CDE82;
    font-family: Inter;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}
.evidence-component .tabs .tab-heading.agree {
    color: #58bc61;
}
.evidence-component .tabs .tab-heading.disagree {
    color: #FE735A;
    font-family: Inter;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;;
}
.evidence-component .tabs .aggree.active {
    background-color: #7CDE82;
    color: #ffffff;
}
.evidence-component .tabs .tab-heading img {
    opacity: 0;
}
.evidence-component .tabs .tab-heading.active img {
    opacity: 1;
}
.evidence-component .tabs .disagree.active {
    background-color: #FE735A;
    color: #ffffff;
}
.evidence-component .tab-content {
    background-color: #2E3643;
}
.evidence-component .tab-content .no-evidence {
    color: rgba(255,255,255,0.87);
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}
.evidence-component .tab-content .item {
    background-color: #1F2937;
    padding: 20px;
    margin-bottom: 6px;
}
.evidence-component .tab-content .item .item-top-bar {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}
.evidence-component .tab-content .item .item-top-bar img {
    display: block;
    max-height: 70px;
    width: auto;
    color: rgba(255,255,255,0.87);
}
.evidence-component .tab-content .item .item-claim {
    padding: 20px 0;
}
.evidence-component .tab-content .item .item-claim .item-count {
    color: #7e7e7e;
    font-weight: bold;
}
.evidence-component .tab-content .item .item-claim .item-claim-text {
    color: rgba(255,255,255,0.87);
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}
.evidence-component .tab-content .item .info-container {
    position: relative;
}
.evidence-component .tab-content .item .info-container .more-info .description {
   /* position: absolute;
    bottom: 10px;
    left: 0;*/
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    /*max-width: 536px;*/
    width: 100%;
}
.evidence-component .tab-content .item .info-container .more-info .sourceurl {
    /*position: absolute;
    bottom: 10px;
    left: 0;*/
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    /*max-width: 536px;*/
    width: 100%;
}
.evidence-component .tab-content .item .info-container .more-info .text-background {
    background-color: #374151;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.3);
    padding: 11px 26px;
}
.evidence-component .tab-content .item .info-container .more-info .tooltip-arrow-container {
    position: relative;
    display: none;
}
.evidence-component .tab-content .item .info-container .more-info .tooltip-arrow-container .tooltip-arrow {
    position: absolute;
    left: 225px;
}
.evidence-component .tab-content .item .info-container .more-info .sourceurl .tooltip-arrow-container .tooltip-arrow {
    left: 130px;
}
.evidence-component .tab-content .item .info {
    display: flex;
    gap: 61px;
    margin-bottom: 20px;
}
.evidence-component .tab-content .item .info .info-item {

}
.evidence-component .tab-content .item .info .ii-title {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
}
.evidence-component .tab-content .item .info .link,
.evidence-component .tab-content .item .info .no-link {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}
.evidence-component .tab-content .item .info .link {
    text-decoration: underline;
    cursor: pointer;
}
.evidence-component .tab-content .item .more-info > div {
    /*padding-bottom: 20px;*/
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}
.evidence-component .tab-content .item .more-info .description,
.evidence-component .tab-content .item .more-info .sourceurl {
    display: none;
}
.evidence-component .tab-content .item .more-info .description.active,
.evidence-component .tab-content .item .more-info .sourceurl.active {
    display: block;
}
.evidence-component .tab-content .item .more-info .sourceurl a {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-decoration: underline;
}
.evidence-component .tab-content .item .certainty-container {
    display: flex;
    gap: 12px;
}
.evidence-component .tab-content .item .certainty-level {
    color: #7e7e7e;
    font-family: "Futura PT";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
}
.evidence-component .tab-content .item .certainty-level .cl-label {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
}
.evidence-component .tab-content .item .certainty-level .cl-value {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}

.evidence-component .progress-container {
    position: relative;
    height: 100px;
    width: 100px;
    flex-basis: 25%;
}
.evidence-component .progress-container .progress-cfs {
    z-index: 1;
    position: absolute;
    right: 0;
}
.evidence-component .progress-container .progress-nfs {
    z-index: 2;
    position: absolute;
    width: 76px;
    height: 76px;
    top: 12px;
    right: 12px;
}
.evidence-component .progress-bar .loading-circle-cfs {
    visibility:hidden;height:0;width:0;
}
.evidence-component .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: progress 2s 1 forwards;
}
.evidence-component .hide-evidence {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 29px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    cursor: pointer;
}

@media all and (max-width: 700px) {
    .evidence-component .claim-summary {
        flex-wrap: wrap;
    }
    .evidence-component .claim-summary .claim-info {
        flex-wrap: wrap;
        gap: 20px;
    }
    .evidence-component .claim-summary .column-left,
    .evidence-component .claim-summary .column-right {
        flex-basis: 100%;
    }
    .evidence-component .tab-content .item .info {
        flex-wrap: wrap;
    }
    .evidence-component .hide-evidence {
        justify-content: flex-start;
    }
    .evidence-component .progress-container .progress-cfs {
        right: unset;
        left: 0;
    }
    .evidence-component .progress-container .progress-nfs {
        right: unset;
        left: 12px;
    }
}
@media all and (max-width: 600px) {
    .evidence-component .tabs {
        flex-wrap: wrap;
    }
    .evidence-component .tabs .tab-heading {
        flex-basis: 100%;
    }
    .evidence-component .tab-content .item .more-info .sourceurl a {
        word-break: break-all;
    }
}
@media all and (max-width: 500px) {
    .evidence-component .tab-content .item .info {
        gap: 31px;
    }
    .evidence-component .tab-content .item .info .info-item {
        flex-basis: 100%;
    }
    .evidence-component .tab-content .item .info-container .more-info .sourceurl {
        bottom: 80px;
    }
    .evidence-component .tab-content .item .info-container .more-info .tooltip-arrow-container .tooltip-arrow,
    .evidence-component .tab-content .item .info-container .more-info .sourceurl .tooltip-arrow-container .tooltip-arrow {
        left: 15px;
    }
}
