.internal-header-container {
    background-color: #292C31;
}
.internal-header-container .header {
    max-width: 1640px;
    margin: 0 auto;
}
.internal-page-template {
    background-color: #ffffff;

    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5em;
}
.internal-page-template > .inner {
    padding: 53px 69px;
    max-width: 1640px;
    margin: 0 auto;
}
.internal-page-template h1 {
    font-weight: 500;
    margin-bottom: 40px;
}
.internal-page-template p {
    margin-top: 0;
}
.internal-page-template .tp-account a {
    text-decoration: none;
}
.internal-page-template .tp-account .account-actions {
    margin: 60px 0;
}
.internal-page-template .tp-account .account-actions > * {
    margin-bottom: 15px;
}
.internal-page-template .tp-account .account-actions .btn {
    display: inline-block;
}
