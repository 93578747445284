.factcheck-component {
    position: sticky;
    top: 40px;
}
.factcheck-component .return-to-article {
    cursor: pointer;
}
.factcheck-component .claims .claim {
    display: flex;
    position: relative;
    margin: 6px 0;
    padding: 25px 25px 25px 53px;
    background-color: #1F2937;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.05);
}
.factcheck-component .claims .claim .claim-line-icon {
    position: absolute;
    left: 17px;
    top: 25px;
}
.factcheck-component .btn-back {
    color: #ffffff;
    background-color: black;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 15px;
    text-decoration: none;
}
.factcheck-component .btn-view {
    color: #ffffff;
    background-color: #cccccc;
    border-radius: 3px;
    display: inline-block;
    padding: 6px 15px;
    text-decoration: none;
    cursor: pointer;
}
.factcheck-component .action-bar {
    padding-bottom: 30px;
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
}
.factcheck-component .claims .claim-text {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 22px;
}
.factcheck-component .claims .claim-text.mostly-entail span {
   border-bottom: 2px solid #58bc61;
}
.factcheck-component .claims .claim-text.mostly-refute span {
    border-bottom: 2px solid #FE735A;
}
.factcheck-component .claims .claim-scores {
    margin-bottom: 30px;
}
.factcheck-component .claims .claims-title {
    color: rgba(255,255,255,0.87);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    margin: 19px 25px
}
.factcheck-component .progress-bar .loading-circle-cfs {
    visibility:hidden;height:0;width:0;
}
.factcheck-component .progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: progress 2s 1 forwards;
}
.original-sentence-container .progress-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 100px;
    width: 100px;
    flex-basis: 25%;
}
.original-sentence-container .sentence {
    flex-basis: 70%;
}
.original-sentence-container .sentence.wide {
    flex-basis: 100%;
}
.original-sentence-container .sentence .not-checked {
    color: #ffffff;
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
}
.original-sentence-container .sentence .not-checked .ratings {
    margin-top: 30px;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.original-sentence-container .sentence strong {
    margin-bottom: 7px;
    display: inline-block;

    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.original-sentence-container .original-sentence {
    color: #ffffff;
    padding: 3px;

    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
}
.original-sentence-container .original-sentence.mostly-entail {
    background-color: #58bc61;
}
.original-sentence-container .original-sentence.mostly-refute {
    background-color: #FE735A;
}
.original-sentence-container .summary {
    color: #ffffff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
}
.original-sentence-container .summary-title {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-transform: uppercase;
}
.progress-container .progress-cfs {
    z-index: 1;
    position: absolute;
    right: 0;
}
.progress-container .progress-nfs {
    z-index: 2;
    position: absolute;
    width: 76px;
    height: 76px;
    top: 12px;
    right: 12px;
}
.original-sentence-container {
    display: flex;
    justify-content: space-between;
    background-color: #1F2937;
    padding: 19px 25px;
}
.original-sentence-container .summary {
    display: flex;
    gap: 61px;
    padding-top: 30px;
}
.factcheck-component .claim-summary {
    display: flex;
    gap: 61px;
}
.factcheck-component .claim-summary .cs-heading {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
}
.factcheck-component .claim-summary .cs-value {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
}
.factcheck-component .claim .column-left {
    flex-basis: 80%;
}
.factcheck-component .claim .column-right {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.factcheck-component .claim .column-right .view-evidence {
    margin-top: 120px;
    cursor: pointer;

    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
}
.factcheck-component .claim .column-right .view-evidence img {
    margin-right: 12px;
}
.factcheck-component .claim .claim-counter {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;

    margin-bottom: 9px;
}

@media all and (max-width: 768px) {
    .factcheck-component .claim-summary {
        flex-wrap: wrap;
        gap: 31px;
    }
    .factcheck-component .claim .column-right {
        min-width: 130px;
    }
}
@media all and (max-width: 600px) {
    .factcheck-component .claims .claim {
        flex-wrap: wrap;
        gap: 40px;
    }
    .factcheck-component .claim .column-left {
        flex-basis: 100%;
    }
    .factcheck-component .claim .column-right {
        flex-basis: 100%;
        justify-content: flex-start;
        min-height: 120px;
    }
    .progress-container .progress-cfs {
        right: unset;
        left: 24px;
    }
    .progress-container .progress-nfs {
        left: 36px;
        right: unset;
    }
    .original-sentence-container .sentence {
        flex-basis: 100%;
    }
    .original-sentence-container .progress-container {
        display: none;
    }
    .original-sentence-container .summary {
        flex-wrap: wrap;
    }
}


