.home-component {
    display: flex;
    height: 100%;
    justify-content: center;
}
.home-component .txt-underline {
    text-decoration: underline;
}
.home-component .txt-bold {
    font-weight: bold;
}
.home-component .left-column {
    background-color: #ffffff;
    /*max-width: 683px;*/
    width: 100%;
}
.home-component .right-column {
    background-size: cover;
    background-position: center;
    max-width: 957px;
    width: 100%;
}
.home-component .right-column video {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 957px;
}
.home-component .action-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-top: 25px;
}
.home-component form {
    padding: 123px 71px 123px 65px;
}
.home-component textarea {
    border: 1px solid #D8D8D8;
    color: #161616;
    font-family: Inter;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 31px;
    padding: 25px 14.5px 25px 23.5px;
    width: 100%;
    min-height: 50vh;
    /*background-image: url("http://localhost:3000/images/home-txt-background.png");*/
    background-position: center;
    background-repeat: no-repeat;
}
.help-item {
    position: relative;
    z-index: 9992;
}
.how-to {
    display: flex;
    background: linear-gradient(180deg, #35CCFF 0%, #2C5CFF 100%);
    box-shadow: 0 0 13px 0 rgba(0,0,0,0.2);
    position: fixed;
    bottom: 10vh;
    right: 5vw;
    z-index: 99;
    padding: 14px;
}
.how-to img {
    display: block;
}
.how-to .help {
    border-right: 1px solid #ffffff;
    padding: 0 12px 0 0;
    cursor: pointer;
}
.how-to .new {
    padding: 0 0 0 12px;
    cursor: pointer;
}
.help-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow: auto;
    background-color: rgba(0,0,0,0.75);
}
.help-overlay img {
    display: block;
    max-width: 100%;
    height: auto;
}
.help-overlay .top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 50px;
}
.help-overlay .top-bar img {
    cursor: pointer;
}
.help-overlay .image-container {
    /*min-height: 547px;*/
    min-height: 60vh;
    position: relative;
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
}
.help-overlay .image-container img {
    margin: 0 auto;
    display: block;
    height: auto;
    max-width: 100%;
}
.help-overlay .image-container .help-overlay-1 {
    position: absolute;
    top: 160px;
    /*left: 636px;*/
    left: 50%;
}
.help-overlay .image-container .help-overlay-2 {
    position: absolute;
    /*top: 535px;
    left: 461px;*/
    top: 61vh;
    left: 40%;
}
.help-overlay .bottom-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.help-overlay .bottom-bar .btn-outline {
    border: 2px solid #2D6EFF;
    display: inline-block;
    padding: 15px 45px;
    cursor: pointer;

    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}
.slide-controls {
    flex-basis: 100%;
    margin-bottom: 61px;
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
}
.slide-controls .circles {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}
.slide-controls .circles .circle {
    border: 1px solid #D8D8D8;
    border-radius: 50%;
    display: inline-block;
    padding: 5px;
}
.slide-controls .circles .circle.active {
    background-color: #D8D8D8;
}
.slide-controls .prev,
.slide-controls .next {
    cursor: pointer;
}

@media all and (max-width: 1000px) {
    .how-to {
        display: none;
    }
    .home-component .left-column {
        max-width: 100%;
    }
    .home-component .right-column {
        display: none;
    }
}
@media all and (max-width: 600px) {
    .home-component form {
        padding: 60px 40px;
    }
}
