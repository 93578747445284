.menu-slide-out {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(41, 44, 49,0.6);
    z-index: 9999;
    overflow: hidden;
    display: none;
}
.menu-slide-out .mso-inner {
    background-color: #292C31;
    color: #ffffff;
    height: 100%;
    width: 300px;
    padding: 25px;
    position: fixed;
    left: -300px;
    transition: all 1s;
}
.menu-slide-out.open {
    display: block;
}
.menu-slide-out.open .mso-inner {
    left: 0;
}
.menu-slide-out .mso-inner .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.menu-slide-out .mso-inner .close-menu {
    text-align: end;
}
.menu-slide-out .mso-inner .close-menu .close-icon {
    transform: rotate(45deg);
    cursor: pointer;
}
.menu-slide-out ul, .menu-slide-out li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.menu-slide-out li {
    margin-bottom: 10px;
}
.menu-slide-out a {
    text-decoration: none;
    color: #D8D8D8;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
}
.header {
    background-color: #292C31;
    /*padding: 50px 71px 50px 65px;*/
    padding: 25px 71px 25px 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header .action-container {
    display: flex;
    align-items: center;
    gap: 20px;
}
.header .action-container img {
    cursor: pointer;
}

@media all and (max-width: 600px) {
    .header {
        padding: 30px 40px;
    }
}
