.footer {
    background-color: #292C31;
}
.footer .copyright {
    color: #777777;
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    text-align: center;
    padding: 25px;
}
.footer .inner {
    max-width: 1640px;
    padding: 53px 0;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.footer .left {
    display: flex;
    max-width: 683px;
    width: 100%;
    padding-left: 69px;
}
.footer .left .logo-container {
    margin-right: 67px;
}
.footer .left .text {
    color: #FFFFFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    max-width: 315px;
    width: 100%;
}
.footer .left .text p {
    margin: 0;
}
.footer .right {
    border-left: 1px solid #555555;
    padding-left: 68px;
    max-width: 957px;
    width: 100%;
    padding-left: 69px;
}
.footer .right .footer-menu {
    display: flex;
}
.footer .right .footer-menu .col {
    max-width: 189px;
    width: 100%;
}
.footer .right .footer-menu ul,
.footer .right .footer-menu li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer .right .footer-menu a {
    color: #D8D8D8;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: none;
    margin-bottom: 8px;
    display: inline-block;
}

@media all and (max-width: 1000px) {
    .footer .inner {
        flex-wrap: wrap;
        padding: 50px 71px 50px 65px;
    }
    .footer .left {
        padding-left: 0;
        margin-bottom: 40px;
    }
    .footer .left .logo-container {
        margin-bottom: 40px;
    }
    .footer .right {
        padding-left: 0;
        border: 0;
    }
}
@media all and (max-width: 600px) {
    .footer .inner {
        padding: 30px 40px;
    }
}
@media all and (max-width: 500px) {
    .footer .left {
        flex-wrap: wrap;
    }
    .footer .right .footer-menu {
        flex-wrap: wrap;
    }
}
